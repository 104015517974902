import React, { useCallback, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import './details.css';

export const Summary = ({ className, isExpanded, onClick, children, ...props }) => {
  return (
    <div
      role="button"
      className={classNames(
        className,
        'flex items-center justify-between text-2xl pb-4 list-none cursor-pointer',
      )}
      onClick={onClick}
      {...props}
    >
      <span className="whitespace-normal font-semibold">{children}</span>
      <ChevronDownIcon
        className={classNames('h-4 w-4 flex-shrink-0 arrow transition', {
          'rotate-180': isExpanded,
        })}
      />
    </div>
  );
};

export default function Details({ className, children, ...props }) {
  const [summary, ...content] = children;
  const [isExpanded, setIsExpanded] = useState(false);

  const [height, setHeight] = useState(0);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.scrollHeight);
    }
  }, []);

  return (
    <div
      ref={measuredRef}
      className={classNames(className, `overflow-hidden ease-in-out duration-300 mt-3 lg:mt-0`)}
      style={{
        height: isExpanded ? height : 64,
      }}
      {...props}
    >
      {React.cloneElement(summary, {
        isExpanded,
        onClick: () => setIsExpanded((x) => !x),
      })}
      {content}
    </div>
  );
}
