import React, { useState } from 'react';
import { Link } from 'gatsby';

function App() {
  const [email, setEmail] = useState('');

  return (
    <>
      <div className="bg-tan">
        <div className="container mx-auto px-1 sm:px-2 md:px-3 lg:px-10 flex-grow pt-4 pb-5 md:py-5">
          <footer className="px-1 sm:px-2 md:px-3 lg:px-0 w-full">
            <div className="flex flex-wrap md:flex-nowrap">
              <div className="flex-grow flex flex-col w-full">
                <h3 className="mt-2 text-center md:text-left w-full flex-nowrap">
                  Sign up for our newsletter
                </h3>
              </div>
              <div className="flex-grow flex flex-col md:flex-row items-center md:justify-end w-full">
                <form
                  action="https://stanford.us1.list-manage.com/subscribe/post"
                  method="POST"
                  className="flex justify-center"
                  name="contact"
                  netlify-honeypot="decoy-field"
                  data-netlify="true"
                  data-netlify-recaptcha="true"
                >
                  <input type="hidden" name="u" value="ba817fd1048da64abd9d92190" />
                  <input type="hidden" name="id" value="410746919c" />
                  <div
                    className="field-shift hidden"
                    aria-label="Please leave the following three fields empty"
                    aria-hidden="true"
                  >
                    <label htmlFor="b_name">Name: </label>
                    <input
                      type="text"
                      name="b_name"
                      tabindex="-1"
                      value=""
                      placeholder="Freddie"
                      id="b_name"
                    />
                    <label htmlFor="b_email">Email: </label>
                    <input
                      type="email"
                      name="b_email"
                      tabindex="-1"
                      value=""
                      placeholder="youremail@gmail.com"
                      id="b_email"
                    />

                    <label htmlFor="b_comment">Comment: </label>
                    <textarea
                      name="b_comment"
                      tabindex="-1"
                      placeholder="Please comment"
                      id="b_comment"
                    ></textarea>
                  </div>
                  <div className="max-w-full flex flex-col items-end">
                    <div className="flex flex-col items-center md:flex-row">
                      <input
                        type="email"
                        autocapitalize="off"
                        autocorrect="off"
                        name="MERGE0"
                        id="MERGE0"
                        size="25"
                        className="mt-4 md:mt-0 lg:py-3 px-4 md:px-9 w-68 md:min-w-[300px] text-[14px] py-2"
                        placeholder="YOUR E-MAIL ADDRESS"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <input
                        type="submit"
                        name="submit"
                        value="Subscribe"
                        className="bg-bronze button cta2 flex items-center justify-center font-medium cursor-pointer text-center text-white w-60 py-2 px-4 mt-4 md:mt-0 md:px-2 md:mx-0 lg:mx-0 xl:mx-0 2xl:mx-0"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="container mx-auto px-5 lg:px-0 flex flex-col items-center my-5">
        <div className="w-16 h-16 text-center mb-5">
          <img className="" src="/images/Logo.svg" alt="project logo" />{' '}
        </div>
        <div className="flex gap-5 flex-wrap justify-center">
          <Link to="/">
            <p className="text-[15px] font-[400] text-[#3D3D3D]">Home</p>
          </Link>
          <Link to="/about">
            <p className="text-[15px] font-[400] text-[#3D3D3D]">About</p>
          </Link>
          <Link to="/#pilotstable">
            <p className="text-[15px] font-[400] text-[#3D3D3D]">Pilots</p>
          </Link>
          <Link to="/stories">
            <p className="text-[15px] font-[400] text-[#3D3D3D]">Stories</p>
          </Link>
          <Link to="/in-the-news">
            <p className="text-[15px] font-[400] text-[#3D3D3D]">In the News</p>
          </Link>
        </div>
        <div className="flex justify-center mt-5 mb-3">
          <a href="https://hyperobjekt.com">
            <p className="text-[14px] font-[400] text-[#757575]">Site by Hyperobjekt</p>
          </a>
        </div>
      </div>
    </>
  );
}

export default App;
