import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Details, { Summary } from 'src/components/Details';
import Layout from 'src//components/layout';
import SEO from 'src/components/seo';
import Signup from '../../components/Signup';

const About = () => {
  const data = useStaticQuery(graphql`
    query aboutData {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/about.md/" } }) {
        edges {
          node {
            frontmatter {
              intro
              categories {
                category_name
                faqs {
                  question
                  answer
                }
              }
            }
          }
        }
      }
    }
  `);

  const { intro, categories } = data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter ?? {};

  return (
    <Layout>
      <SEO title="About" />
      <main className="bg-bronze about-page">
        <div className="container mx-auto py-4 sm:py-6 lg:py-10 px-5 lg:px-0">
          <div className="w-full lg:w-1/2 text-white ">
            <h1 className="mb-4">About the Dashboard</h1>
            <ReactMarkdown
              children={intro}
              components={{ a: (props) => <a className="!text-white" {...props} /> }}
            />
          </div>
        </div>
      </main>

      <div className="bg-tan">
        <div className="container mx-auto mt-9 px-5 lg:px-0">
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>
      {categories.map((category, i) => (
        <section className={twMerge(i % 2 ? 'bg-brown' : 'bg-tan', 'about-page')}>
          <div className="container mx-auto px-5 mb-9 lg:px-0">
            <div className="w-full flex justify-between flex-wrap mt-10">
              <div className="w-full lg:w-1/4">
                <h3>{category.category_name}</h3>
              </div>
              <div className="w-full lg:w-3/4">
                {category.faqs.map((faq, i, arr) => (
                  <>
                    <Details>
                      <Summary>{faq.question}</Summary>
                      <ReactMarkdown children={faq.answer} />
                    </Details>
                    {i !== arr.length - 1 && <hr className="border-t-2" />}
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
      ))}
      <section className="bg-white about-page py-9">
        <div className="container mx-auto px-5 lg:px-0">
          <div className="w-full block mx-auto">
            <div className="headings uppercase text-center mb-6">Project partners</div>
            <div className="flex flex-wrap justify-center md:justify-between items-center w-1/2 md:w-full lg:w-3/4 mx-auto gap-2 md:gap-0">
              <a href="https://basicincome.stanford.edu/" target="_blank" rel="noreferrer noopener">
                <img className="max-h-[40px]" src="/images/bil-logo.png" />
              </a>
              <a href="https://www.mayorsforagi.org/" target="_blank" rel="noreferrer noopener">
                <img className="max-h-[56px]" src="/images/mgi-logo.png" />
              </a>
              <a href="https://www.penncgir.org/" target="_blank" rel="noreferrer noopener">
                <img className="max-h-[56px]" src="/images/cgir-logo.png" />
              </a>
            </div>
            <div className="flex flex-wrap justify-center md:justify-between items-center w-1/2 md:w-full lg:w-[75%] mx-auto mt-8 gap-2 md:gap-0">
              <a href="https://www.steadyapp.com/" target="_blank" rel="noreferrer noopener">
                <img className="max-h-[56px]" src="/images/steady-logo.svg" />
              </a>
              <a href="https://mocafi.com/" target="_blank" rel="noreferrer noopener">
                <img className="max-h-[56px]" src="/images/mocafi-logo.png" />
              </a>
              <a
                href="https://www.communityfinancialresources.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="max-h-[56px]" src="/images/community-logo.png" />
              </a>
              <a href="https://usio.com/" target="_blank" rel="noreferrer noopener">
                <img className="max-h-[56px]" src="/images/final-usio-color.png" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Signup />
    </Layout>
  );
};

/*
<a href="https://basicincome.stanford.edu/" target="_blank" rel="noreferrer noopener">

*/

export default About;
